import { RedocStandalone } from "redoc";

const APIDocumentation = () => {
  return (
    <RedocStandalone
      specUrl="http://petstore.swagger.io/v2/swagger.json"
      options={{
        nativeScrollbars: true,
        theme: { colors: { primary: { main: "#dd5522" } } },
      }}
    />
  );
};

export default APIDocumentation;
