import Container from "@mui/material/Container";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import Divider from "@mui/material/Divider";
import { minWidth } from "@mui/system";
import Grid from "@mui/material/Grid";
import { Link, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";

const Login = () => {
  const handleSubmit = () => {};

  const handleReset = () => {};
  const navigate = useNavigate();

  return (
    <div style={{ background: `#422E8F` }}>
      <Container maxWidth="sm" sx={{ alignItems: "center" }}>
        <div style={{ height: "100vh", alignItems: "center", display: "flex" }}>
          <Card
            elevation={2}
            variant="elevation"
            sx={{
              alignItems: "center",
              minHeight: "300px",
              minWidth: "500px",
              padding: 2,
              // background: "grey",
            }}
          >
            <CardHeader title="Sing up Dev Portal" />
            <Divider sx={{ marginBottom: 3 }} />
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <TextField
                  placeholder="Name"
                  onChange={(event) => event}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  placeholder="Email"
                  onChange={(event) => event}
                  fullWidth
                />
              </Grid>

              <Grid item xs={12}>
                <TextField
                  type="password"
                  placeholder="Password"
                  onChange={(event) => event}
                  fullWidth
                />
              </Grid>
            </Grid>
            <div style={{ margin: "24px", textAlign: "right" }}>
              <Button
                sx={{ marginRight: "16px" }}
                onClick={(event) => handleReset()}
              >
                Reset
              </Button>
              <Button
                sx={{ minWidth: "150px" }}
                variant="contained"
                onClick={() => navigate("/doc")}
              >
                Login
              </Button>
              <Typography variant="body1" sx={{ marginTop: 2 }}>
                Already have an account?
                <Link onClick={() => navigate("/auth/login")}>Sign in</Link>
              </Typography>
            </div>
          </Card>
        </div>
      </Container>
    </div>
  );
};

export default Login;
