import { Navigate, useRoutes } from "react-router-dom";
import Login from "../pages/auth/Login";
import Register from "../pages/auth/Register";
import APIDocumentation from "../pages/APIDocumentation";

// import your route components too

const Router = () =>
  useRoutes([
    {
      path: "auth",
      children: [
        {
          path: "login",
          element: <Login />,
        },

        {
          path: "register",
          element: <Register />,
        },
      ],
    },
    {
      path: "doc",
      element: <APIDocumentation />,
    },
    {
      path: "/",
      element: <Login />,
    },
  ]);
export default Router;
