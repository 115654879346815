import { CssBaseline } from "@mui/material";
import {
  createTheme,
  ThemeProvider as MUIThemeProvider,
  StyledEngineProvider,
} from "@mui/material/styles";
import { ReactNode } from "react";

interface OwnProps {
  children: ReactNode;
}

export default function ThemeProvider({ children }: OwnProps) {
  const theme = createTheme({
    palette: {
      primary: {
        main: "#422E8F",
      },
    },
  });

  return (
    <StyledEngineProvider injectFirst>
      <MUIThemeProvider theme={theme}>
        <CssBaseline />
        {children}
      </MUIThemeProvider>
    </StyledEngineProvider>
  );
}
